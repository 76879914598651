<template>
  <div class="editor editor__wrapper">
    <div v-if="editor" class="editor__btns-wrapper">
      <button
        @click="editor.chain().focus().toggleBold().run()"
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <svg-icon name="txt_bold" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_bold') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('italic') }"
        @click="editor.chain().focus().toggleItalic().run()"
      >
        <svg-icon name="txt_italic" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_italic') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('strike') }"
        @click="editor.chain().focus().toggleStrike().run()"
      >
        <svg-icon name="txt_strike" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_strike') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('underline') }"
        @click="editor.chain().focus().toggleUnderline().run()"
      >
        <svg-icon name="txt_underline" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_underline') }}
        </span>
      </button>
      <button
        class="editor__btn"
        @click="editor.chain().focus().clearNodes().run()"
      >
        <svg-icon name="txt_standart" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_standart') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('paragraph') }"
        @click="editor.chain().focus().setParagraph().run()"
      >
        <svg-icon name="txt_paragraph" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_paragraph') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
      >
        <svg-icon name="txt_h1" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_h1') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
      >
        <svg-icon name="txt_h2" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_h2') }}
        </span>
      </button>
      <!-- <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
      >
        <svg-icon name="txt_bold" class="editor__ico" />
        h3
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
      >
        <svg-icon name="txt_bold" class="editor__ico" />
        h4
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
      >
        <svg-icon name="txt_bold" class="editor__ico" />
        h5
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
      >
        <svg-icon name="txt_bold" class="editor__ico" />
        h6
      </button> -->
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        @click="editor.chain().focus().toggleBulletList().run()"
      >
        <svg-icon name="txt_bullet-list" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_bullet-list') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('orderedList') }"
        @click="editor.chain().focus().toggleOrderedList().run()"
      >
        <svg-icon name="txt_ordered-list" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_ordered-list') }}
        </span>
      </button>
      <!-- <button
        @click="editor.chain().focus().toggleCodeBlock().run()"
        :class="{ 'is-active': editor.isActive('codeBlock') }"
      >
        code block
      </button> -->
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive('blockquote') }"
        @click="editor.chain().focus().toggleBlockquote().run()"
      >
        <svg-icon name="txt_blockquote" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_blockquote') }}
        </span>
      </button>
      <button
        class="editor__btn"
        @click="editor.chain().focus().setHorizontalRule().run()"
      >
        <svg-icon name="txt_horizontal-line" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_horizontal-line') }}
        </span>
      </button>
      <button
        class="editor__btn"
        title="(Enter)"
        @click="editor.chain().focus().setHardBreak().run()"
      >
        <svg-icon name="txt_break" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_break') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
        title="(Ctr+Shift+L)"
        @click="editor.chain().focus().setTextAlign('left').run()"
      >
        <svg-icon name="txt_align-left" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_align-left') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
        title="(Ctr+Shift+E)"
        @click="editor.chain().focus().setTextAlign('center').run()"
      >
        <svg-icon name="txt_align-center" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_align-center') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
        title="(Ctr+Shift+R)"
        @click="editor.chain().focus().setTextAlign('right').run()"
      >
        <svg-icon name="txt_align-right" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_align-right') }}
        </span>
      </button>
      <button
        class="editor__btn"
        :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
        title="(Ctr+Shift+J)"
        @click="editor.chain().focus().setTextAlign('justify').run()"
      >
        <svg-icon name="txt_align-justify" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_align-justify') }}
        </span>
      </button>
      <button
        class="editor__btn"
        @click="editor.chain().focus().undo().run()"
        title="(Ctrl+Z)"
      >
        <svg-icon name="txt_undo" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_undo') }}
        </span>
      </button>
      <button
        class="editor__btn"
        title="(Ctrl+Y; Ctrl+Shift+Z)"
        @click="editor.chain().focus().redo().run()"
      >
        <svg-icon name="txt_redo" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.txt_redo') }}
        </span>
      </button>
      <button
        class="editor__btn"
        @click="editor.chain().focus().unsetAllMarks().run()"
      >
        <svg-icon name="txt_clear-all" class="editor__ico" />
        <span class="editor__txt">
          {{ $t('editor.clear_formatting') }}
        </span>
      </button>
    </div>
    <!-- <div class="editor_title">Текст</div> -->
    <editor-content :editor="editor" :class="{ editor_error: isError }" />
    <div v-if="isError" class="error-message">
      {{ $t('error.required_field') }}
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';

import '@/assets/sass/helpers/_text-editor.sass';
export default {
  model: {
    prop: 'value',
    event: 'input',
  },

  components: {
    EditorContent,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit.configure({
          heading: {
            HTMLAttributes: {
              class: 'txt-header',
            },
          },
          paragraph: {
            HTMLAttributes: {
              class: 'txt-paragraph',
            },
          },
          blockquote: {
            HTMLAttributes: {
              class: 'txt-blockquote',
            },
          },
          bulletList: {
            HTMLAttributes: {
              class: 'txt-bullet-list',
            },
          },
          orderedList: {
            HTMLAttributes: {
              class: 'txt-ordered-list',
            },
          },
          horizontalRule: {
            HTMLAttributes: {
              class: 'horizontal-rule',
            },
          },
        }),
        Underline,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
      ],
      onUpdate: () => {
        this.$emit('input', this.editor.getHTML());
      },
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value;

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(value, false);
    },
  },

  computed: {
    getValue: {
      get() {
        return this.editor;
      },
      set(val) {
        const isSame = this.editor.getHTML() === val;

        if (isSame) {
          return;
        }

        this.editor.commands.setContent(val, false);
      },
    },
  },
};
</script>
