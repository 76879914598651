<template>
  <div class="profile__wrapper news-block">
    <div class="news-block__section">
      <h4 class="news-block__title" :class="{ required_el: !isEditNews }">
        {{ $t('main.news_title') }}
      </h4>
      <text-field v-model="name" :is-error="$v.name.$error" />
    </div>

    <div class="news-block__section">
      <h4 class="news-block__title">
        {{ $t('main.news_tags') }}
      </h4>
      <text-field v-model="tags" />
    </div>

    <div class="news-block__section">
      <h4
        class="news-block__title news-block__title_element"
        :class="{ required_el: !isEditNews }"
      >
        {{ $t('main.news_txt') }}
      </h4>
      <text-editor v-model="text" :is-error="$v.text.$error" />
    </div>

    <div class="news-block__section files">
      <h4
        class="news-block__title news-block__title_element"
        :class="{ required_el: !isEditNews }"
      >
        {{ $t('main.news_photo') }}
      </h4>
      <div class="news-block__img-block">
        <PhotoUpload
          v-model="file"
          @uploadFiles="uploadFiles($event, item)"
          :valid-formats="validFormats"
          :max-size-k-b="fileMaxSize"
          :is-error="$v.file.$error"
          id="newsImg"
        />
        <div class="news-block__error-msg" v-if="$v.file.$error">
          {{ $t(`error.required_field`) }}
        </div>
        <div class="news-block__info-block">
          <svg-icon name="info_light" class="news-block__info-ico" />
          <div class="">
            <div class="">
              {{ this.$t('tooltip.file_upload_tooltip_1') }}
            </div>
            <div class="">
              {{ this.$t('tooltip.file_upload_tooltip_2') }}
              {{ this.validFormats.join(', ') }}
            </div>
            <div class="">
              {{ this.$t('tooltip.file_upload_tooltip_3') }}
              {{ this.fileMaxSize / 1000 }}
              {{ this.$t('tooltip.file_upload_tooltip_4') }}
            </div>
          </div>
        </div>
      </div>

      <div class="news-block__section_switcher">
        <h3 class="news-block__title-switcher">
          {{ $t('main.main_news') }}
        </h3>
        <Switcher v-model="main" />
      </div>
    </div>

    <div class="news-block__buttons-block">
      <template v-if="isEditNews">
        <Button transparent class="news-block__button"
          >{{ $t('buttons.cancel') }}
        </Button>
        <Button class="news-block__button" @click.native="saveNews"
          >{{ $t('buttons.make_improvement') }}
        </Button>
      </template>
      <template v-else>
        <Button
          class="news-block__button"
          icon-name="arrow"
          isRightIcon
          @click.native="saveNews"
          >{{ $t('buttons.create') }}
        </Button>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import { createFile } from '@/library/helpers';
import PhotoUpload from '@/elements/PhotoUpload.vue';
import Switcher from '@/elements/Switcher.vue';
import TextEditor from '@/elements/TextEditor.vue';
import LoaderContent from '@/elements/LoaderContent.vue';
export default {
  components: { PhotoUpload, Switcher, TextEditor, LoaderContent },

  data() {
    return {
      name: '',
      text: '',
      main: false,
      file: null,
      validFormats: ['png', 'jpg', 'jpeg', 'gif'],
      fileMaxSize: 5000,
      tags: '',
    };
  },

  async created() {
    !!!this.$route.params.activity &&
      this.$router.push({ name: 'content-administrator_news-list' });
    if (this.isEditNews) {
      await this.$store.dispatch(
        'getCurrentNewsContentAdmin',
        this.$route.params.id
      );
      await this.getCurrentNews();
    }
  },

  computed: {
    ...mapGetters(['getContentAdminCurrentNews']),

    isEditNews() {
      return this.$route.params.activity === 'edit';
    },

    getDataForSave() {
      let data = new FormData();
      !!this.name && data.append('name', this.name);
      // !!this.tags.length && data.append('tags[]', this.tags.split(','));
      !!this.text && data.append('text', this.text);
      this.tags.split(',').map((el, idx) => {
        data.append(`tags[${idx}]`, el);
      });
      // if(!!this.tags.length) {
      // }
      !!this.file?.length && data.append('image', this.file[0]);
      data.append('main', this.main ? 1 : 0);
      !!this.$route.params?.id && data.append('uuid', this.$route.params.id);
      return data;
    },
  },

  methods: {
    async getCurrentNews() {
      if (!!this.getContentAdminCurrentNews) {
        this.name = this.getContentAdminCurrentNews.name;
        this.text = this.getContentAdminCurrentNews.text;
        this.main = this.getContentAdminCurrentNews.main;
        this.tags = this.getContentAdminCurrentNews.tags.join(', ');

        this.file = [
          await createFile(
            this.getContentAdminCurrentNews.file.original_url,
            this.getContentAdminCurrentNews.file.mime
          ),
        ];
      }
    },

    saveNews() {
      if (this.isEditNews) {
        this.$store.dispatch(
          'updateCurrentNewsContentAdmin',
          this.getDataForSave
        );
      } else {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.$store.dispatch('createNewsContentAdmin', this.getDataForSave);
        }
      }
    },
  },

  validations: {
    name: {
      required,
    },
    text: {
      required,
    },
    file: {
      required,
    },
  },
};
</script>

<style lang="sass" scoped>
.news-block__section
  margin-bottom: 7rem
.news-block__section.files
  max-width: 50%
  @include m
    max-width: 100%

.news-block__section_switcher
  display: flex
  align-items: center
  justify-content: space-between
  // width: 45%
  // @include s
  //   flex-direction: column
  //   align-items: start
  //   width: 100%

.news-block__img-block
  margin-bottom: 50px

.news-block__info-block
  margin-top: 20px
  color: $gray
  font-size: 12px
  font-weight: 100
  display: flex
  border: solid 1px $gray
  padding: 24px
  border-radius: 12px
  // width: 45%
  min-width: 286px

.news-block__info-ico
  fill: $gray_notification
  width: 20px
  height: 20px
  margin-right: 10px

.news-block__title
  color: #B6B6B6
  font-size: 16px
  font-weight: 300
  margin-bottom: 10px

.news-block__title_element
  margin-bottom: 30px

.news-block__title-switcher
  font-size: 18px
  color: $black
  margin-right: 30px
  @include s
    // margin: 0 0 30px 0

.news-block__buttons-block
  display: flex
  justify-content: flex-end
  @include xs
    flex-direction: column
    align-items: center

.news-block__button
  &:first-child
    margin-right: 20px
    @include xs
      margin: 0 0 20px 0

.news-block__error-msg
  color: $error
  font-size: 12px
  padding-top: 5px
</style>

